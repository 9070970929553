<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品类型</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">类型名称：</div>
                <el-input v-model="search.typeName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">所属分类：</div>
                <el-select v-model="search.belonging" placeholder="请选择">
                  <el-option label="推荐" :value="1"></el-option>
                  <el-option label="穿越飞船" :value="2"></el-option>
                  <el-option label="玩乐达人" :value="3"></el-option>
                </el-select>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="commodityTypeInfoAdd" @click="goAdd">新增商品类型</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="typeName" label="类型名称"></el-table-column>
            <el-table-column align="center" label="类型图标">
              <template slot-scope="scope"><img @click="handleSee(scope.row.typeIcon)" class="icon-img"
                                                :src="scope.row.typeIcon"/></template>
            </el-table-column>
            <el-table-column align="center" label="是否为商品类型">
              <template slot-scope="scope">{{ scope.row.optional == 0 ? "否" : "是" }}</template>
            </el-table-column>
            <el-table-column align="center" label="所属分类">
              <template slot-scope="scope">
                {{ scope.row.belonging == 1 ? "推荐" : scope.row.belonging == 2 ? "穿越飞船" : "玩乐达人" }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="commodityTypeInfoUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="commodityTypeInfoDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="类型名称：" prop="typeName">
          <span v-if="type === 'details'"> {{ upData.typeName }}</span>
          <el-input v-else v-model="upData.typeName" placeholder="请输入类型名称"></el-input>
        </el-form-item>
        <el-form-item label="所属分类：" prop="belonging">
          <span v-if="type === 'details'"> {{
              upData.belonging == 1 ? "推荐" : upData.belonging == 2 ? "穿越飞船" : "玩乐达人"
            }}</span>
          <el-select v-else v-model="upData.belonging" placeholder="请选择所属分类">
            <el-option label="推荐" :value="1"></el-option>
            <el-option label="穿越飞船" :value="2"></el-option>
            <el-option label="玩乐达人" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为商品类型：" prop="optional">
          <span v-if="type === 'details'"> {{ upData.optional == 0 ? "否" : "是" }}</span>
          <el-select v-else v-model="upData.optional" placeholder="请选择是否为商品类型">
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <span v-if="type === 'details'"> {{ upData.sort }}</span>
          <el-input v-else type="number" v-model="upData.sort" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="类型图标：" prop="typeIcon">
          <div class="flexRow">
            <div class="up-image" v-if="type === 'details'">
              <div class="image-list">
                <img @click="handleSee(upData.typeIcon)" :src="upData.typeIcon" alt/>
              </div>
            </div>
            <div class="up-image" v-else>
              <div v-for="(item, index) in upList" class="image-list" :key="index">
                <img @click="handleSee(item.url)" :src="item.url" alt/>
                <i class="el-icon-error" @click="removeImage(index)"></i>
              </div>
            </div>
            <el-upload
                v-if="type !== 'details'"
                action
                accept=".jpg, .jpeg, .png, .gif"
                :on-exceed="onExceed"
                :on-error="onError"
                :file-list="upList"
                list-type="picture-card"
                :show-file-list="false"
                :auto-upload="true"
                :http-request="fnUploadRequest"
                :before-upload="beforeFileUpload"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>

      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validatorTypeIcon = (rule, value, callback) => {
      if (this.upData.typeIcon === "") {
        callback(new Error("请上传类型图标"));
      } else {
        callback();
      }
    }
    return {
      search: {
        typeName: "",
        belonging: null
      },
      type: "",
      tableList: [],
      upList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        typeName: "",
        typeIcon: "",
        belonging: null,
        optional: 0,
        sort: 0,
      },
      rules: {
        typeName: [{required: true, message: "请输入类型名称", trigger: "blur"}],
        belonging: [{required: true, message: "请选择所属分类", trigger: "change"}],
        typeIcon: [{required: true, validator: validatorTypeIcon, trigger: "change"}]
      },
      commodityTypeInfoAdd: false,
      commodityTypeInfoDel: false,
      commodityTypeInfoUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getCommodityTypeInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "commodityTypeInfo:add") {
          _this.commodityTypeInfoAdd = true;
        }
        if (v == "commodityTypeInfo:del") {
          _this.commodityTypeInfoDel = true;
        }
        if (v == "commodityTypeInfo:upd") {
          _this.commodityTypeInfoUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addCommodityTypeInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.handleClose();
                _this.getData();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          } else {
            _this.$api.editCommodityTypeInfo(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.btnLoading = false;
                _this.getData();
                _this.handleClose();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
        }else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        typeName: "",
        typeIcon: "",
        belonging: null,
        optional: 0,
        sort: 0,
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        typeName: "",
        belonging: null
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        typeName: "",
        typeIcon: "",
        belonging: null,
        optional: 0,
        sort: 0,
      }
      _this.upList = [];
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.typeIcon
      });
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.typeIcon
      });
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delCommodityTypeInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
            _this.$message.error('删除失败');
          });
    },
    // 上传start
    handleRemove() {
      let _this = this;
      _this.typeIcon = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      _this.upList.splice(e, 1);
      _this.upData.typeIcon = "";
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.typeIcon = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}
</style>
